(function($) {
    jQuery(function($) {
        let start = moment().subtract(6, "days");
        let end = moment();

        $('input[name="date-ranger"]').daterangepicker({
            singleDatePicker: false,
            startDate: start,
            endDate: end,
            ranges: {
                "Hoy": [moment(), moment()],
                "Ayer": [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                ],
                "Últimos 7 Dias": [moment().subtract(6, "days"), moment()],
                "Este Mes": [moment().startOf("month"), moment().endOf("month")],
                "Mes Pasado": [
                    moment()
                        .subtract(1, "month")
                        .startOf("month"),
                    moment()
                        .subtract(1, "month")
                        .endOf("month")
                ]
            }
        }, function(start, end, label) {
            Livewire.emit('filter-date-range', start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
            console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
        });

        window.initSelect2 = () => {
            console.log("init");
            jQuery(".select2").select2({
                placeholder: 'Seleccione una opción'
            });

            jQuery('.select2').on('change', function (e) {
                var data = $(this).select2("val");
                var field = $(this).data('model');

                window.livewire.emit('setField', field, data);
            });
        }

        initSelect2();

        window.livewire.on('select2', ()=>{
            initSelect2();            
        });

        $(document).on('click', 'a.approve', function(event) {
            var el = $(this);
            event.preventDefault();
            Swal.fire({
                title: '¿Está seguro?',
                text: "Esta a punto de aprobar este registro",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#9ACC33',
                cancelButtonColor: '#666',
                confirmButtonText: 'Aprobar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                    el.prev().submit();
                }
              });
        });

        $(document).on('click', 'a.submit', function(event) {
            var el = $(this);
            event.preventDefault();
            el.prev().submit();
        });

        $(document).on('click', 'a.remove', function(event) {
            var el = $(this);
            event.preventDefault();
            Swal.fire({
                title: '¿Está seguro?',
                text: "Esta a punto de borrar el registro de la base de datos",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#666',
                confirmButtonText: 'Borrar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                    el.prev().submit();
                }
              });
        });

        $('.all-checkboxes').on('click', function(event) {
            $(this).closest('form').find('input[type="checkbox"]').prop('checked', this.checked);
        });

        $(".numeric-only").on('keydown', function (event) {
            console.log(event.keyCode);
            if (event.shiftKey == true) {
                event.preventDefault();
            }

            if ((event.keyCode >= 48 && event.keyCode <= 57) || 
                (event.keyCode >= 96 && event.keyCode <= 105) || 
                event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 ||
                event.keyCode == 39 || event.keyCode == 46 || event.keyCode == 109 || event.keyCode == 110 || event.keyCode == 173 || event.keyCode == 190 || event.keyCode == 189) {
    
            } 
            else {
                event.preventDefault();
            }

            if($(this).val().indexOf('-') !== -1 && (event.keyCode == 109 || event.keyCode == 173 || event.keyCode == 189))
                event.preventDefault(); 
    
            if($(this).val().indexOf('.') !== -1 && (event.keyCode == 110 || event.keyCode == 190))
                event.preventDefault(); 
            //if a decimal has been added, disable the "."-button
    
        });

        $(document).on('change', '.deactivate_institution input[name="is_active"]', function(event) {
            var el = $(this);
            event.preventDefault();
            if(el.val() == 0){
                $('.deactivate_functions').show();
            }
            else{
                $('.deactivate_functions').hide();
            }            
        });

        $(document).on('change', '.custom-file-input', function(event) {
            var filename = $(this)[0].files.length ? $(this)[0].files[0].name : "";
            $(this).next('.custom-file-label').html(filename);
        });

    });

    class UploadAdapter {
        constructor( loader ) {
            // The file loader instance to use during the upload.
            this.loader = loader;
        }

        // Initializes the XMLHttpRequest object using the URL passed to the constructor.
        _initRequest() {
            const xhr = this.xhr = new XMLHttpRequest();

            // Note that your request may look different. It is up to you and your editor
            // integration to choose the right communication channel. This example uses
            // a POST request with JSON as a data structure but your configuration
            // could be different.
            xhr.open( 'POST', '/backend/upload', true);
            xhr.setRequestHeader('x-csrf-token', $('meta[name="csrf-token"]').attr('content'));
            xhr.responseType = 'json';
        }

        // Initializes XMLHttpRequest listeners.
        _initListeners( resolve, reject, file ) {
            const xhr = this.xhr;
            const loader = this.loader;
            const genericErrorText = `Couldn't upload file: ${ file.name }.`;

            xhr.addEventListener( 'error', () => reject( genericErrorText ) );
            xhr.addEventListener( 'abort', () => reject() );
            xhr.addEventListener( 'load', () => {
                const response = xhr.response;

                // This example assumes the XHR server's "response" object will come with
                // an "error" which has its own "message" that can be passed to reject()
                // in the upload promise.
                //
                // Your integration may handle upload errors in a different way so make sure
                // it is done properly. The reject() function must be called when the upload fails.
                if ( !response || response.error ) {
                    return reject( response && response.error ? response.error.message : genericErrorText );
                }

                // If the upload is successful, resolve the upload promise with an object containing
                // at least the "default" URL, pointing to the image on the server.
                // This URL will be used to display the image in the content. Learn more in the
                // UploadAdapter#upload documentation.
                resolve( {
                    default: response.url
                } );
            } );

            // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
            // properties which are used e.g. to display the upload progress bar in the editor
            // user interface.
            if ( xhr.upload ) {
                xhr.upload.addEventListener( 'progress', evt => {
                    if ( evt.lengthComputable ) {
                        loader.uploadTotal = evt.total;
                        loader.uploaded = evt.loaded;
                    }
                } );
            }
        }

        // Prepares the data and sends the request.
        _sendRequest( file ) {
            // Prepare the form data.
            const data = new FormData();

            data.append( 'upload', file );
            data.append( 'upload', file );

            // Important note: This is the right place to implement security mechanisms
            // like authentication and CSRF protection. For instance, you can use
            // XMLHttpRequest.setRequestHeader() to set the request headers containing
            // the CSRF token generated earlier by your application.

            // Send the request.
            this.xhr.send( data );
        }

        // Starts the upload process.
        upload() {
            return this.loader.file
                .then( file => new Promise( ( resolve, reject ) => {
                    this._initRequest();
                    this._initListeners( resolve, reject, file );
                    this._sendRequest( file );
                } ) );
        }

        // Aborts the upload process.
        abort() {
            if ( this.xhr ) {
                this.xhr.abort();
            }
        }
    }

    function MyUploadAdapterPlugin( editor ) {
        editor.plugins.get('FileRepository').createUploadAdapter = ( loader ) => {
            // Configure the URL to the upload script in your back-end here!
            return new UploadAdapter(loader);
        };
        editor.on('insertElement', function(event) {
            var element = event.data;
            if (element.getName() == 'img') {
                element.addClass('img-fluid');
            }
        });
    }

    var editors = $('.ckeditor');

    if(editors) {

        // loop through all editors
        for (var i = 0; i < editors.length; i++) {

            var element = editors[i];

            ClassicEditor.create(element, {
                extraPlugins: [ MyUploadAdapterPlugin ],
                image: {
                    styles: [
                        'alignLeft', 'alignCenter', 'alignRight'
                    ],
                    resizeOptions: [
                        {
                            name: 'resizeImage:original',
                            label: 'Original',
                            value: null
                        },
                        {
                            name: 'resizeImage:75',
                            label: '75%',
                            value: '75'
                        },
                        {
                            name: 'resizeImage:50',
                            label: '50%',
                            value: '50'
                        }
                    ],
                    toolbar: [
                        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                        '|',
                        'resizeImage',
                        '|',
                        'imageTextAlternative'
                    ]
                }
            })
            .then( editor => {
                console.log(editor);
                editor.model.document.on('change:data', () => {
                    var field = $(element).data('model');

                    window.livewire.emit('setField', field, editor.getData());
                });
            })
            .catch( error => {
                console.error(error);
            });
        }
    }
})(jQuery);